import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Project Structure`}</h2>
    <p>{`To get the best out of your work in Figma, it helps to keep your designs projects orderly so you can easily cooperate with all stakeholders. Giving your projects a clear structure helps keep everyone on the same page`}</p>
    <br />
    <br />
    <h2>{`Project Naming`}</h2>
    <p>{`Make sure in advance the structure and type of your design project, whether it is a tribe or ecosystem and name it in the following format`}</p>
    <ul>
  <li>
    Ecosystem Project : Ecosystem <b>(Ecosystem Name)</b>, ex : Ecosystem Logee
  </li>
  <li>
    Tribe Project : Tribe of <b>(Tribe Name)</b>, ex : Tribe of Education (EDU)
  </li>
    </ul>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/design/project-structure/project-naming.png",
      "alt": "project setup"
    }}></img>
    <div className="divi" />
    <h2>{`Folder Naming`}</h2>
    <p>{`If the structure and type of your design project have been adjusted, then the next step is to name the project folder according to the following format`}</p>
    <ul>
  <li>
    Ecosystem Project : <b>(Tribe Name)</b> - <b>(Squad Name)</b>, ex : IHX - My Indihome X
  </li>
  <li>
    Tribe Project : <b>(Squad Name)</b>, ex : Pijar Belajar
  </li>
    </ul>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/design/project-structure/folder-naming.png",
      "alt": "folder naming"
    }}></img>
    <br />
    <br />
    <h2>{`Design System Folder`}</h2>
    <p>{`Don’t forget to create a folder containing all the tribe or ecosystem design system files to be used as a library in one project.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/design/project-structure/design-system-folder.png",
      "alt": "design system folder"
    }}></img>
    <div className="divi" />
    <h2>{`File Structure`}</h2>
    <p>{`The figma design file structure contains the design that is being made by the designer and needs to be standardized in page composition so as to make it easier to read the contents of the created design file. There are 2 option template that can be used as a guide. You can used the template file with click `}<strong parentName="p">{`See Tempalte`}</strong>{`, then duplicate and move or drag to your ptoject folder.`}</p>
    <div {...{
      "className": "row2"
    }}>{`
  `}<div parentName="div" {...{
        "className": "column1"
      }}>{`
    `}<img parentName="div" {...{
          "className": "imageleft",
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/design/project-structure/guide-9.png",
          "alt": "file structure"
        }}></img>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "double-column1"
      }}>{`
    `}<p parentName="div" {...{
          "className": "text-xl font-bold mb-2"
        }}>{`Option : Sprint Template`}</p>{`
    `}<p parentName="div" {...{
          "className": "mb-2"
        }}>{`
      Used in the project is to use sprint calculations, it is more advisable to use this version, by breaking up
      sprints per page, due to the large number of sprints & screens made in 1 sprint.
    `}</p>{`
    `}<anchor parentName="div" {...{
          "className": "Anchor",
          "size": "md",
          "href": "https://www.figma.com/community/file/1152459505204142608"
        }}>{`
      See Template
    `}</anchor>{`
  `}</div>
    </div>
    <br />
    <div {...{
      "className": "row2"
    }}>{`
  `}<div parentName="div" {...{
        "className": "column1"
      }}>{`
    `}<img parentName="div" {...{
          "className": "imageleft",
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/design/project-structure/guide-10.png",
          "alt": "project structure"
        }}></img>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "double-column1"
      }}>{`
    `}<p parentName="div" {...{
          "className": "text-xl font-bold mb-2"
        }}>{`Option : Version Template`}</p>{`
    `}<p parentName="div" {...{
          "className": "mb-2"
        }}>{`
      Used in the project is to use versioning, for example Hifi v.1, Hifi v.2 and so on. it is better to use this
      version by placing the sprint on the page version (eg Hifi v.1) because it is possible that there are not many
      sprints & screens. Usually used on existing apss/design when revamping.
    `}</p>{`
    `}<anchor parentName="div" {...{
          "className": "Anchor",
          "size": "md",
          "href": "https://www.figma.com/community/file/1152459253536557583"
        }}>{`
      See Tempalte
    `}</anchor>{`
  `}</div>
    </div>
    <br />
    <br />
    <h2>{`File Name & Thumbnail`}</h2>
    <p>{`Then please adjust the thumbnail and design file naming with the following format`}</p>
    <ul>
  <li>
    File Thumbnail : Use a thumbnail with a minimum of Squad Name, platform & annotation information from the file
  </li>
  <li>
    File Naming : For writing the file name can be adjusted to the format <b>(Info / Platform)</b> - <b>(Squad Name)</b>
  </li>
    </ul>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/design/project-structure/file-name-thumbnail.png",
      "alt": "file and thumbnail"
    }}></img>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      